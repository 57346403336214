// Next.js
import Link from '../Link'
import Image from 'next/image'

// React components
import Button from '../Button'

// Styled Components
import {
  ArticleCardWrapper,
  Date,
  Text,
  Container,
  ButtonWrapper,
  ImageWrapper,
} from './ArticleCard.styles'
import { Title } from '../../styled-components/shared/common'

// Types
import { ArticleCardProps } from './ArticleCard.types'

// Helpers
import { formatDate } from '../../utils/helpers'
import useDictionaryLine from '../../hooks/useDictionary'
import dictionary from '../../utils/dictionary'

const ArticleCard = ({
  title,
  date,
  text,
  href,
  imageUrl,
  imageAlt,
  highlighted = false,
}: ArticleCardProps) => {
  const continueReadingLocale = useDictionaryLine(
    dictionary.articles.continueReading,
  )

  return (
    <ArticleCardWrapper highlighted={highlighted}>
      <Link href={href} passHref legacyBehavior>
        <ImageWrapper highlighted={highlighted}>
          <Image
            src={imageUrl}
            layout='fill'
            alt={imageAlt}
            objectFit='cover'
            priority={highlighted}
          />
        </ImageWrapper>
      </Link>
      <Container highlighted={highlighted}>
        <Title>{title}</Title>
        <Date>{formatDate(date)}</Date>
        <Text
          dangerouslySetInnerHTML={{
            __html: text.replace(/<figure[^>]*>[\s\S]*?<\/figure>/g, '') || '',
          }}
        />
        <ButtonWrapper>
          <Link href={href} passHref legacyBehavior>
            <Button variant='outlined' arrow={true} link>
              {continueReadingLocale}
            </Button>
          </Link>
        </ButtonWrapper>
      </Container>
    </ArticleCardWrapper>
  )
}
export default ArticleCard
